<!-- 优惠活动详情 -->
<template>
    <div class="page-promo page-elec">
        <!-- <div class="promo-img">
            <img loading="lazy" v-lazy="require('../../assets/images/index/banner.png')"> 
        </div> -->
        <div class="promo-content-box">
            <div class="page-promo-info">
                <!-- <img class="close-img" src="../../assets/images/other/black_me_a001_close02.png" alt="" @click="back"> -->
                <div class="info-cont-inner">{{ title }}</div>
                <div class="promo-subtitle" style="text-align: left;justify-content: start;">
                    <div class="promo-type"> {{ forever == 1 ? $t("promoInfo.text2") : $t("promoInfo.text3")}}</div>
                    <div class="promo-time">{{ time }}</div>
                </div>
                <!-- 内容 -->
                <div v-html="info"></div>
                <!-- 底部按钮 -->
                <div>
                    <!-- 状态 -->
                    <div>
                        <!-- 咨询客服领取 -->
                        <template v-if="infoData.type == 2">
                            <button @click="_toCustomerService">{{$t("promoInfo.text26")}}</button>
                        </template>
                        <!-- 站内活动 -->
                        <template v-if="infoData.type && infoData.type != 2">
                            <!-- 活动生效，立即参与 -->
                            <button @click="partIn" v-if="!infoData.auditStatus && typeof infoData.auditStatus == 'object' && infoData.isEffect">{{$t("promoInfo.text7")}}</button>
                            <!-- 进行中 -->
                            <template v-if="typeof infoData.auditStatus == 'number'">
                                <div>{{$t("promoInfo.text8")}}：{{ promoStatus(infoData) }}</div>
                                <div>
                                    <!-- 查看活动记录 -->
                                    <button v-if="actRecordsList.length > 0" @click="openActRecords">{{$t("promoInfo.text9")}}</button>
                                </div>
                            </template>
                            <!-- 未参与，活动截止 -->
                            <template v-if="!infoData.auditStatus && typeof infoData.auditStatus == 'object' && !infoData.isEffect">
                                <div>{{$t("promoInfo.text10")}}</div>
                                <div>
                                    <!-- 活动局数记录 -->
                                    <div v-for="(item, i) in activityCompletionArr" :key="i">
                                        <span>{{ item[0] }}：</span>
                                        <span>¥</span><span>{{ _setNumFixed(item[1], 2) }}</span>
                                    </div>
                                    <!-- 查看活动记录 -->
                                    <button v-if="actRecordsList.length > 0" @click="openActRecords">{{$t("promoInfo.text11")}}</button>
                                </div>
                            </template>
                        </template>
                    </div>
                </div>

                <!-- 活动记录-弹窗 -->
                <Dialog :title='$t("promoInfo.text12")' :visible.sync="dialogVisible" width="666" center>
                    <Table :data="actRecordsList" border>
                        <TableColumn prop="censusDate" :label='$t("promoInfo.text13")'></TableColumn>
                        <TableColumn prop="activityCompletionStr" :label='$t("promoInfo.text14")'></TableColumn>
                        <TableColumn prop="auditStatus" :label='$t("promoInfo.text15")'></TableColumn>
                    </Table>
                </Dialog>
            </div>
        </div>
    </div>
</template>

<script>
import { _setNumFixed } from '../../core/utils/utils'
import { _toCustomerService } from '../../core/comp/common'
import { _partIn, _promoStatus } from '../../core/comp/pages/promo'
import { Dialog, Table, TableColumn } from 'element-ui'
export default {
    components: {
        Dialog,
        Table,
        TableColumn,
    },
    data () {
        return {
            id: '', // 活动id
            title: '', // 活动标题
            forever: '', // 时效 1-长期活动 0-限时活动 
            time: '', // 活动时间
            info: '', // 活动详情
            infoData: {}, // 活动数据
            activityCompletionArr: [], // 活动局数记录
            actRecordsList: [], // 活动记录列表
            dialogVisible: false, // 活动记录弹窗开关
        }
    },
    mounted () {
        this.init()
    },
    methods: {
        _toCustomerService,
        _setNumFixed,
        // 初始化数据
        init () {
            const query = this.$route.query
            this.id = query.pid
            this.title = query.title
            this.time = query.time
            this.forever = query.forever
            this.info = localStorage.getItem('promoInfo')
            console.log(this.info);
            try {
                this.infoData = JSON.parse(localStorage.getItem('promoInfoData'))
            } catch {
                console.erroe('编译活动数据失败')
                this.infoData = {}
            }
            // 活动记录
            this.actRecordsList = []
            if (this.infoData.list && this.infoData.list.length) {
                // 活动记录数据处理
                this.actRecordsList = this.infoData.list.map(item => {
                    // 日期
                    if (item.censusDate) {
                        const date = new Date(item.censusDate)
                        item.censusDate = `${date.getMonth() + 1}月${date.getDate()}日`
                    } else {
                        item.censusDate = '--/--'
                    }
                    // 完成条件
                    if (!item.activityCompletionStr) item.activityCompletionStr = '--/--'
                    // 状态
                    item.auditStatus = this.promoStatus(item)
                    return item
                })
            }
            // 活动局数
            const activityCompletion = this.infoData.activityCompletion
            this.activityCompletionArr = []
            if (activityCompletion && JSON.stringify(activityCompletion) != '{}') {
                for (const k in activityCompletion) {
                    this.activityCompletionArr.push({
                        0: k,
                        1: activityCompletion[k]
                    })
                }
            }
        },
        // 返回
        back () {
            this.$router.back(-1)
        },
        // 获取活动状态
        promoStatus (activityData) {
            return _promoStatus(activityData)
        },
        // 立即参与
        partIn () {
            _partIn().then(res => {
                if (res) this.init()
            })
        },
        // 查看活动记录
        openActRecords () {
            this.dialogVisible = true
        }
    }
}
</script>

<style lang="less" scoped>
@import url("../../assets/css/page-promo-info.less");
.info-cont-inner {
    margin-top: 50px;
    font-size: 25px;
    font-weight: bold;
    color: #f3b401;
}
</style>